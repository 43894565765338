import { useTranslation } from 'react-i18next';
import { Stack, TextField, Grid, Chip, Button } from '@mui/material';
import { useCallback, useState } from 'react';
import DataWrapper from '../../../../components/Display/DataWrapper';
import _isEmpty from 'lodash/isEmpty';
import { Box } from '@mui/system';
import LoadingButton from '../../../../components/form/LoadingButton';

const SearchForm = (props) => {
    const {
        selection,
        bookingFetcher,
        t,
        onSearchClick,
        onBookingLoaded,
        onError
    } = props

    const [data, setData] = useState({ locator: "" })
    const [loading, setLoading] = useState(false)
    const [noData, setNoData] = useState(false)

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const handleOnClick = useCallback(() => {
        const locator = data.locator.trim()

        if (_isEmpty(locator)) {
            return
        }

        if (selection.filter((item) => item.locator === locator).length > 0) {
            onError(t("billing_client_create_invoice_widget.steps.bookings.repeated_booking_error", { locator: locator }))
            return
        }

        onSearchClick()
        setLoading(true)
        setNoData(false)
        bookingFetcher(locator)
            .then((booking) => {
                onBookingLoaded(booking)
                setLoading(false)
            }).catch(error => {
                switch (error.message) {
                    case "not_found":
                        error.message = t("billing_client_create_invoice_widget.steps.bookings.not_found_error", { locator: locator })
                        break
                    case "not_allowed":
                        error.message = t("billing_client_create_invoice_widget.steps.bookings.not_allowed_error", { locator: locator })
                        break
                    default:
                        break
                }

                onError(error.message)
                setLoading(false)
            })
    }, [data.locator, t, bookingFetcher, onSearchClick, onBookingLoaded, onError])

    return (
        <DataWrapper title={t("billing_client_create_invoice_widget.steps.bookings.title")}>
            <Grid container spacing={2} sx={{ marginTop: 0 }}>
                <Grid item>
                    <TextField
                        id="locator"
                        name="locator"
                        label={t("bookings.fields.locator")}
                        fullWidth
                        value={data.locator}
                        onChange={handleInputChange}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        autoFocus
                    />
                </Grid>
                <Grid item>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        color="primary"
                        onClick={handleOnClick}
                    >
                        {t("common.find")}
                    </LoadingButton>
                </Grid>
            </Grid>
            {noData && <Grid container spacing={2} sx={{ marginTop: 1, textAlign: "center" }}>
                <Grid item>
                    <Box sx={{ color: "info.main" }}>{t("table.no_data")}</Box>
                </Grid>
            </Grid>}
        </DataWrapper>
    )
}

const ItemsList = (props) => {
    const { selection, disableConfirmButton, t, onItemDeleted, onConfirm } = props

    return (
        <Stack spacing={2}>
            <DataWrapper title={t("billing_client_create_invoice_widget.steps.bookings.selected_bookings")}>
                <Stack spacing={2} sx={{ marginTop: 1 }}>
                    <Box>
                        {selection.map((booking) => (<Chip
                            key={booking.id}
                            label={booking.locator}
                            variant="outlined"
                            onDelete={() => onItemDeleted(booking)}
                            color="primary"
                            sx={{ marginRight: 2 }}
                        />))}
                    </Box>
                </Stack>
            </DataWrapper>
            <Box display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disableConfirmButton}
                    onClick={() => onConfirm(selection)}
                >
                    {t("common.continue")}
                </Button>
            </Box>
        </Stack>
    )
}

const BookingsSelector = (props) => {
    const {
        selection,
        bookingFetcher,
        onSelection,
        onError
    } = props

    const { t } = useTranslation()

    const [data, setData] = useState(selection)
    const [isReady, setIsReady] = useState(selection.length > 0)

    const handleOnBookingLoaded = (booking) => {
        setData([
            ...data,
            booking
        ])
        setIsReady(true)
    }

    const handleOnItemDeleted = (booking) => {
        setData(data.filter((item) => item.id !== booking.id))
        setIsReady(true)
    }

    return (
        <Stack spacing={2}>
            <SearchForm
                selection={data}
                bookingFetcher={bookingFetcher}
                t={t}
                onSearchClick={() => setIsReady(false)}
                onBookingLoaded={handleOnBookingLoaded}
                onError={onError}
            />
            {data.length > 0 && <ItemsList
                selection={data}
                disableConfirmButton={!isReady}
                t={t}
                onItemDeleted={handleOnItemDeleted}
                onConfirm={() => onSelection({ bookings: data })}
            />}
        </Stack>
    )
}

export default BookingsSelector