export const getGroupedPermissions = (permissions) => {
    return Object.entries(Object.groupBy(permissions, ({ module }) => module))
        .map((module) => {
            const submodules = Object.entries(Object.groupBy(module[1], ({ submodule }) => submodule))
                .map((submodule) => {
                    const permissions = submodule[1].map((permission) => {
                        return {
                            id: permission.id,
                            name: `${permission.description} (${permission.name})`,
                        }
                    })

                    return {
                        id: `s_${submodule[0]}`,
                        name: submodule[0],
                        value: permissions
                    }
                })

            return {
                id: `m_${module[0]}`,
                name: module[0],
                value: submodules
            }
        })
}