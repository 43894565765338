import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Field } from "formik"
import { Box } from "@mui/system"
import { FormHelperText } from "@mui/material"
import _isEmpty from "lodash/isEmpty"
import 'react-phone-input-2/lib/material.css'
import PhoneInput from "react-phone-input-2"
import es from 'react-phone-input-2/lang/es.json'
import fr from 'react-phone-input-2/lang/fr.json'
import it from 'react-phone-input-2/lang/it.json'
import de from 'react-phone-input-2/lang/de.json'
import pl from 'react-phone-input-2/lang/pl.json'
import ru from 'react-phone-input-2/lang/ru.json'

const customPhoneMasks = {
    ee: '... ....'
}

const getLocalization = (language) => {
    switch (language) {
        case "es":
            return es
        case "fr":
            return fr
        case "de":
            return de
        case "it":
            return it
        case "pl":
            return pl
        case "ru":
            return ru
        default:
            return es
    }
}

const getDefaultCountry = (language) => {
    switch (language) {
        case "es":
            return "es"
        case "de":
            return "de"
        case "it":
            return "it"
        case "fr":
            return "fr"
        case "nl":
            return "nl"
        case "pl":
            return "pl"
        case "ru":
            return "ru"
        default:
            return "gb"
    }
}

const validate = (t, value, country) => {
    if (_isEmpty(value) || !country) {
        return null
    }

    const format = country.format.replace('+', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
    if (value.length !== format.length) {
        // check formats extensions

        // estonia
        if (country.countryCode === 'ee' && value.length === 11) {
            return null
        }
        
        // germany
        if (country.countryCode === 'de' && value.length >= 12) {
            return null
        }

        // lithuania
        if (country.countryCode === 'lt' && value.length === 11) {
            return null
        }

        // netherlands
        if (country.countryCode === 'nl' && value.length === 11) {
            return null
        }

        // austria entre 6 y 15
        if (country.countryCode === 'at' && (value.length > 5 && value.length < 16)) {
            return null
        }

        // hungary
        if (country.countryCode === 'hu' && (value.length >= 9 && value.length < 13)) {
            return null
        }

        // greece
        if (country.countryCode === 'gr' && (value.length >= 9 && value.length < 13)) {
            return null
        }

        //marruecos prefix +212 and 8 or 9 digits
        if (country.countryCode === 'ma' && (value.length >= 9 && value.length < 13)) {
            return null
        }

        return t("common.form.validation.invalid_phone")
    }

    return null
}

const PhoneFormField = ({ field, value }) => {
    const { t, i18n } = useTranslation("vbms")
    const [country, setCountry] = useState(null)

    const locale = getLocalization(i18n.language)
    const label = field.label
    const defaultCountry = getDefaultCountry(i18n.language)

    return (
        <Box
            sx={{
                "& .react-tel-input": {
                    "& .special-label": {
                        fontSize: "0.65rem",
                        color: "rgba(0, 0, 0, 0.6)",
                        left: "10px",
                    },
                    "& .form-control": {
                        width: "100%",
                        height: "1.4375em",
                        lineHeight: "1.4375em",
                        padding: "17.5px 14px 17.5px 58px",
                        fontSize: "body1.fontSize",
                    }
                }
            }}
        >
            <Field
                name={field.id}
                label={label}
                validate={(value) => validate(t, value, country)}
            >
                {({
                    field,
                    form: { touched, errors, setFieldValue, setFieldTouched },
                    meta,
                }) => (
                    <>
                        <PhoneInput
                            country={defaultCountry}
                            preferredCountries={["es", "gb", "de", "fr"]}
                            masks={customPhoneMasks}
                            localization={locale}
                            enableLongNumbers={true}
                            onChange={(value, country) => {
                                setCountry(country)
                                // wait until the country is set
                                setTimeout(() => {
                                    setFieldValue(field.name, value, true)
                                    setFieldTouched(field.name, true, false)
                                }, 50)
                            }}
                            specialLabel={label}
                            inputProps={{
                                name: field.id,
                            }}
                            value={meta.value}
                        />
                        {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                    </>
                )}
            </Field>
        </Box>
    )
}

export default PhoneFormField
