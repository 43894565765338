import { ArrayDataTableProvider } from "../entity/context/collection/collection.context"
import DataTable from "./DataTable"

const ArrayDataTable = (props) => {

    return (
        <ArrayDataTableProvider data={props.data}>
            <DataTable
                selection={props.selection}
                filter={props.filter}
                columns={props.columns}
                actions={props.actions}
                hideFilter={true}
            />
        </ArrayDataTableProvider>
    )
}

export default ArrayDataTable
