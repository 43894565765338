/** Generic */
const PERM_ACCESS_ANY_ENTERPRISE = "access-any-enterprise"

/** Orders */
const PERM_BOOKINGS_ADMINISTER_ANY = "administer-any-booking"
const PERM_BOOKINGS_ADMINISTER_OWN = "administer-own-bookings"
const PERM_BOOKINGS_VIEW = "view-any-booking"
const PERM_BOOKINGS_VIEW_OWN = "view-own-bookings"
const PERM_BOOKINGS_VIEW_NET_PRICE = "view-booking-net-price"

/** Invoices */
const PERM_INVOICES_VIEW = "view-any-invoices"
const PERM_INVOICES_VIEW_OWN = "view-own-invoices"

/** CRM */
const PERM_CRM_INTERMEDIARY_VIEW = "view-any-intermediary"
const PERM_CRM_INTERMEDIARY_VIEW_OWN = "view-own-intermediaries"
const PERM_CRM_INTERMEDIARY_CREATE = "create-intermediaries"
const PERM_CRM_INTERMEDIARY_ADMIN = "administer-any-intermediary"
const PERM_CRM_INTERMEDIARY_ADMIN_OWN = "administer-own-intermediaries"
const PERM_CRM_INTERMEDIARY_OFFICE_VIEW = "view-any-intermediary-office"
const PERM_CRM_SALESMAN_VIEW = "view-any-salesman"
const PERM_CRM_INTERMEDIARY_ADMIN_PAYMENT_TOKEN = "administer-intermediary-payment-token"
const PERM_CRM_INTERMEDIARY_USE_PAYMENT_TOKEN = "use-intermediary-payment-token"

/** Users */
const PERM_APPLICATION_USERS_VIEW = "view-application-user"
const PERM_ADMIN_OWN_USER = "administer-own-user"
const PERM_VIEW_OWN_USER = "view-own-user"

/** Catalog */
const PERM_CATALOG_ADMINISTER = "administer-any-catalog-entity"
const PERM_CATALOG_VIEW = "view-any-catalog-entity"
const PERM_DISCOUNT_CODES_ADMINISTER = "administer-any-discount-code"
const PERM_DISCOUNT_CODES_VIEW = "view-any-discount-code"

/** Roles */
const PERM_ROL_ADMINISTER = "administer-roles"

/** Enterprises */
const PERM_ENTERPRISES_ADMINISTER = "administer-any-enterprise"
const PERM_ENTERPRISES_ADMINISTER_OWN = "administer-own-enterprise"
const PERM_ENTERPRISES_VIEW = "view-any-enterprise"
const PERM_ENTERPRISES_VIEW_OWN = "view-own-enterprise"
const PERM_ENTERPRISES_PAYMENT_GATEWAYS_VIEW = "view-payment-gateways"
const PERM_ENTERPRISES_ADMIN_CURRENCIES = "administer-currencies"
const PERM_ENTERPRISES_VIEW_CURRENCIES = "view-currencies"

/** Booking validations */
const PERM_BOOKING_VALIDATION_VIEW     = "view-booking-validations"

/** Refunds */
const PERM_BOOKING_REFUNDS_VIEW = "view-any-booking-refunds"
const PERM_BOOKING_REFUNDS_VIEW_OWN = "view-own-booking-refunds"


export default {
    PERM_ACCESS_ANY_ENTERPRISE,
    PERM_BOOKINGS_ADMINISTER_ANY,
    PERM_BOOKINGS_ADMINISTER_OWN,
    PERM_BOOKINGS_VIEW,
    PERM_BOOKINGS_VIEW_OWN,
    PERM_BOOKINGS_VIEW_NET_PRICE,
    PERM_INVOICES_VIEW,
    PERM_INVOICES_VIEW_OWN,
    PERM_CRM_INTERMEDIARY_VIEW,
    PERM_CRM_INTERMEDIARY_VIEW_OWN,
    PERM_CRM_INTERMEDIARY_CREATE,
    PERM_CRM_INTERMEDIARY_ADMIN,
    PERM_CRM_INTERMEDIARY_ADMIN_OWN,
    PERM_CRM_INTERMEDIARY_OFFICE_VIEW,
    PERM_CRM_INTERMEDIARY_ADMIN_PAYMENT_TOKEN,
    PERM_CRM_INTERMEDIARY_USE_PAYMENT_TOKEN,
    PERM_APPLICATION_USERS_VIEW,
    PERM_CRM_SALESMAN_VIEW,
    PERM_CATALOG_ADMINISTER,
    PERM_CATALOG_VIEW,
    PERM_DISCOUNT_CODES_ADMINISTER,
    PERM_DISCOUNT_CODES_VIEW,
    PERM_ADMIN_OWN_USER,
    PERM_VIEW_OWN_USER,
    PERM_ROL_ADMINISTER,
    PERM_ENTERPRISES_ADMINISTER,
    PERM_ENTERPRISES_ADMINISTER_OWN,
    PERM_ENTERPRISES_VIEW,
    PERM_ENTERPRISES_VIEW_OWN,
    PERM_BOOKING_VALIDATION_VIEW,
    PERM_ENTERPRISES_PAYMENT_GATEWAYS_VIEW,
    PERM_BOOKING_REFUNDS_VIEW,
    PERM_BOOKING_REFUNDS_VIEW_OWN
}   