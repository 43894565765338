import userPermissions from "../../../lib/user-permissions"
import availableRoutes from "../../../routes/availableRoutes"
import { getActions as getRowActions } from "../DiscountCodesPage/DiscountCodesPage.actions"

export const getActions = (t, navigate, user) => [
    {
        title: t("common.create"),
        onExecute: () => {
            navigate(availableRoutes.discount_codes_add.path)
        },
        condition: () => user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)
    },
]


export const getTableActions = (...props) => getRowActions(...props)