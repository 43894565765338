import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import IntermediaryUsers from './components/tabs/IntermediaryUsers'
import IntermediarySales from './components/tabs/IntermediarySales'
import IntermediaryOffices from './components/tabs/IntermediaryOffices'
import IntermediaryContact from './components/tabs/IntermediaryContact'
import InvoicesTable from "../../../components/Volcano/Invoices/InvoicesTable"
import { downloadFile } from '../../../lib/utils'
import ManagedIntermediaries from './components/tabs/IntermediaryManagedIntermediaries'
import userPermissions from '../../../lib/user-permissions';

export const getTabNavigationItems = (entity, user, apiClient, t) => [
    createTabBarItem(
        t("intermediaries.view.navbar.extended_info"),
        IntermediaryContact,
    ),
    createTabBarItem(
        t("intermediaries.view.navbar.offices"),
        IntermediaryOffices,
        null,
        (entity) => entity.collaborator_id && (user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW))
    ),
    createTabBarItem(
        t("intermediaries.view.navbar.users"),
        IntermediaryUsers,
        null,
        (entity) => entity.collaborator_id && (user.hasPermission(userPermissions.PERM_APPLICATION_USERS_VIEW))
    ),
    createTabBarItem(
        t("intermediaries.view.navbar.sales"),
        IntermediarySales,
        null,
        (entity) => entity.collaborator_id && (user.hasPermission(userPermissions.PERM_BOOKINGS_VIEW) || user.hasPermission(userPermissions.PERM_BOOKINGS_VIEW_OWN))
    ),
    createTabBarItem(
        t("intermediaries.view.navbar.invoices"),
        ({ entity, data }) => (
            <InvoicesTable
                data={entity.collaborator_id ? data.getItems() : []}
                onDownload={
                    (invoice) => {
                        apiClient.invoice
                            .getInvoicePdf(invoice.id)
                            .then((pdf) =>
                                downloadFile(pdf, "invoice_" + invoice.title + ".pdf")
                            );
                    }
                }
            />
        ),
        (intermediary) => apiClient.invoice.getInvoices({ colaborator_id: intermediary.collaborator_id, all_times: true }),
        (entity) => entity.collaborator_id && (user.hasPermission(userPermissions.PERM_INVOICES_VIEW) || user.hasPermission(userPermissions.PERM_INVOICES_VIEW_OWN))
    ),
    createTabBarItem(
        t("intermediaries.view.navbar.managed_intermediaries"),
        ManagedIntermediaries
    ),
];