import DataSetWrapper from "../../../../../components/Display/DataSetWrapper";
import constants from '../../../../../components/entity/constants'
import { useTranslation } from "react-i18next"
import { Grid, Box } from "@mui/material"
import ContactField from "../../../../../components/entity/ContactFieldValue";
import { languagesCode2, createLanguagesMapping, createLegalPersonTypeMapping } from '../../../../../components/helpers/fieldHelper';
import { useVolcanoAuth } from "../../../../../context/VolcanoAuthContext";


const otherInformationFields = (t) => [
    {
        field: "comment",
        label: "intermediaries.fields.comment",
        type: constants.STRING_TYPE
    },
    {
        field: "terms_accepted",
        label: "intermediaries.fields.terms_accepted",
        type: constants.BOOLEAN_TYPE
    },
    {
        field: "subscription_accepted",
        label: "intermediaries.fields.subscription_accepted",
        type: constants.BOOLEAN_TYPE
    },
]

const billingContactFields = (t) => [
    {
        field: "legal_person_type",
        label: "contact.fields.legal_person_type",
        type: constants.STRING_TYPE,
        mapping: createLegalPersonTypeMapping(t)
    },
    {
        field: "name",
        label: "intermediaries.fields.social_name",
        type: constants.STRING_TYPE
    },
    {
        field: "vat_number",
        label: "common.fields.vat_type.nif",
        type: constants.STRING_TYPE
    },
    {
        field: "iban",
        label: "intermediaries.fields.iban",
        type: constants.STRING_TYPE
    },
    {
        field: "contact_details",
        type: constants.ADDRESS_TYPE
    }
]

const BaseGridContainer = ({ children }) => {
    return (
        <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
            pt={2}
            pb={2}
        >
            {children.map((item, index) => (
                <Grid item xs={12} md="auto" key={index}>
                    {item}
                </Grid>
            ))}
        </Grid>
    )
}


const IntermediaryContact = ({ entity }) => {
    const { t } = useTranslation()
    const volcanoAuth = useVolcanoAuth()

    if (!entity) {
        return null;
    }

    return (
        <Box>
            <BaseGridContainer>
                <ContactField
                    title={t("intermediaries.view.facet_marketing_contact")}
                    data={entity.marketing_contact}
                />
                <DataSetWrapper
                    title={t("intermediaries.view.facet_billing_contact")}
                    data={entity.billing_contact}
                    config={billingContactFields(t)}
                    variant="inline"
                />
                <ContactField
                    title={t("intermediaries.view.facet_booking_contact")}
                    data={entity.booking_contact}
                />
                <ContactField
                    title={t("intermediaries.view.facet_administration_contact")}
                    data={entity.admin_contact}
                    languagesMapping={createLanguagesMapping(t, languagesCode2(), true)}
                />
                {!volcanoAuth.user.isIntermediary() && (
                    <DataSetWrapper
                        title={t("intermediaries.view.facet_other_information")}
                        data={entity}
                        config={otherInformationFields(t)}
                        variant="inline"
                    />
                )}
            </BaseGridContainer>
        </Box>
    )
}

export default IntermediaryContact