import { AssignmentReturn, Message, MessageOutlined, ReceiptOutlined, SettingsOutlined, ShopOutlined, SwitchAccessShortcut } from '@mui/icons-material';

export const modulesMenu = [
    {
        key: "intermediaries",
        items: [
            {
                key: "bookings",
                icon: <ShopOutlined />,
                text: "bookings",
                items: [
                    {
                        key: "bookings_add",
                        text: "bookings_add",
                    },
                    {
                        key: "bookings",
                        text: "bookings_view",
                    },
                ],
            },
            {
                key: "settings",
                icon: <SettingsOutlined />,
                text: "settings",
                items: [
                    {
                        key: "intermediaries_intermediary_view",
                        text: "intermediary",
                        path: (user) => ({entityId: user.crm_intermediary.id}),
                        condition: (user) => user.isIntermediary(),
                    },
                    {
                        key: "settings_users",
                        text: "system_users",
                    },
                    {
                        key: "intermediary_offices",
                        text: "intermediary_offices",
                    },
                    {
                        key: "settings_roles",
                        text: "security_roles"
                    },
                    {
                        key: "enterprise",
                        text: "enterprise"
                    }
                ],
            },
            {
                key: "refunds",
                icon: <AssignmentReturn />,
                text: "refunds",
                items: [
                    {
                        key: "refunds",
                        text: "refunds_view",
                    },
                ],
        
            },
            {
                key: "billling",
                icon: <ReceiptOutlined />,
                text: "billing",
                items: [
                    {
                        key: "billing_invoices",
                        text: "invoices"
                    },
                    {
                        key: "liquidations",
                        text: "liquidations"
                    }
                ],
            },          
        ],
    },
    {
        icon: <SettingsOutlined />,
        key: "settings",
        items: [
            {
                key: "settings",
                icon: <SettingsOutlined />,
                text: "settings",
                items: [
                    {
                        key: "enterprise",
                        text: "enterprise"
                    },
                    {
                        key: "settings_users",
                        text: "system_users",
                    },
                    {
                        key: "intermediaries",
                        text: "intermediaries",
                    },
                    {
                        key: "intermediary_offices",
                        text: "intermediary_offices",
                    },
                    {
                        key: "settings_roles",
                        text: "security_roles"
                    },
                    /*{
                        key: "tags",
                        text: "tags",
                    },*/
                    {
                        key: "venues",
                        text: "venues",
                    },
                    {
                        key: "discount_codes",
                        text: "discount_codes"
                    }
                ],
            },
        ]
    },
    {
        icon: <ShopOutlined />,
        key: "bookings",
        items: [
            {
                key: "bookings",
                icon: <ShopOutlined />,
                text: "bookings",
                items: [
                    {
                        key: "bookings_add",
                        text: "bookings_add",
                    },
                    /*{
                        key: "bookings_ocb",
                        text: "bookings_ocb",
                    },*/
                    {
                        key: "bookings",
                        text: "bookings_view",
                    },
                    {
                        key: "bookings_activity",
                        text: "bookings_daily",
                    },
                ],
            },
            {
                key: "refunds",
                icon: <AssignmentReturn />,
                text: "refunds",
                items: [
                    {
                        key: "refunds",
                        text: "refunds_view",
                    },
                ],
        
            },
            {
                key: "billling",
                icon: <ReceiptOutlined />,
                text: "billing",
                items: [
                    {
                        key: "billing_invoices",
                        text: "invoices"
                    },
                    {
                        key: "billing_clients",
                        text: "billing_clients"
                    },
                    {
                        key: "billing_summary",
                        text: "billing_summary"
                    },
                    {
                        key: "liquidations",
                        text: "liquidations"
                    }
                ],
            },
            {
                key: "disfrutare",
                icon: <SwitchAccessShortcut />,
                text: "disfrutare.title",
                open: false,
                items: [
                    {
                        key: "disfrutare_precincts",
                        text: "disfrutare.precincts",
                        items: [
                            {
                                key: "disfrutare_cablecar_precinct",
                                text: "disfrutare.cablecar_precinct",
                                type: "disfrutare",
                            },
                            {
                                key: "disfrutare_observatory_precinct",
                                text: "disfrutare.observatory_precinct",
                                type: "disfrutare",
                            },
                            {
                                key: "disfrutare_activity_precincts",
                                text: "disfrutare.activity_precincts",
                                type: "disfrutare",
                            },
                        ]
                    },
                    {
                        key: "disfrutare_notifications",
                        text: "disfrutare.notifications",
                        type: "disfrutare",
                    },
                ]
            }            
        ],
    },
    {
        icon: <MessageOutlined />,
        key: "human_resources",
        items: [
            {
                key: "human_resources",
                icon: <MessageOutlined />,
                text: "human_resources",
                items: [
                    {
                        key: "suggestions",
                        text: "suggestions"
                    },
                ]
            },
        ]
    },
]

const mainMenu = [
    {
        key: "bookings",
        icon: <ShopOutlined />,
        text: "bookings",
        items: [
            {
                key: "bookings_add",
                text: "bookings_add",
            },
            /*{
                key: "bookings_ocb",
                text: "bookings_ocb",
            },*/
            {
                key: "bookings",
                text: "bookings_view",
            },
            {
                key: "bookings_activity",
                text: "bookings_daily",
            },
        ],
    },
    {
        key: "settings",
        icon: <SettingsOutlined />,
        text: "settings",
        items: [
            {
                key: "enterprise",
                text: "enterprise"
            },
            {
                key: "settings_users",
                text: "system_users",
            },
            {
                key: "intermediaries",
                text: "intermediaries",
            },
            {
                key: "intermediary_offices",
                text: "intermediary_offices",
            },
            {
                key: "settings_roles",
                text: "security_roles"
            },
    /*{
                key: "tags",
                text: "tags",
            },*/
            {
                key: "venues",
                text: "venues",
            },
            {
                key: "discount_codes",
                text: "discount_codes"
            }
        ],
    },
    {
        key: "refunds",
        icon: <AssignmentReturn />,
        text: "refunds",
        items: [
            {
                key: "refunds",
                text: "refunds_view",
            },
        ],

    },
    {
        key: "billling",
        icon: <ReceiptOutlined />,
        text: "billing",
        items: [
            {
                key: "billing_invoices",
                text: "invoices"
            },
            {
                key: "billing_clients",
                text: "billing_clients"
            },
            {
                key: "billing_summary",
                text: "billing_summary"
            },
            {
                key: "liquidations",
                text: "liquidations"
            }
        ],
    },
    {
        key: "human_resources",
        icon: <Message />,
        text: "human_resources",
        items: [
            {
                key: "suggestions",
                text: "suggestions"
            },
        ]
    },
    {
        key: "disfrutare",
        icon: <SwitchAccessShortcut />,
        text: "disfrutare.title",
        open: false,
        items: [
            {
                key: "disfrutare_precincts",
                text: "disfrutare.precincts",
                items: [
                    {
                        key: "disfrutare_cablecar_precinct",
                        text: "disfrutare.cablecar_precinct",
                        type: "disfrutare",
                    },
                    {
                        key: "disfrutare_observatory_precinct",
                        text: "disfrutare.observatory_precinct",
                        type: "disfrutare",
                    },
                    {
                        key: "disfrutare_activity_precincts",
                        text: "disfrutare.activity_precincts",
                        type: "disfrutare",
                    },
                ]
            },
            {
                key: "disfrutare_notifications",
                text: "disfrutare.notifications",
                type: "disfrutare",
            },
        ]
    }
];

export default mainMenu;
