import { useEffect } from 'react'
import { AsyncContextProvider, useAsyncContent } from "../../context/async.context"
import Loading from "../Display/Loading"
import useSnackBars from "../Snackbar/snack-bar.context"
import FormikEntityForm from './FormikEntityForm'

const AsyncEntityFormContent = ({ formContent, acceptButtonTitle, variant, onSave, onChange }) => {
    const { data, error } = useAsyncContent()
    const { addAlert } = useSnackBars()

    useEffect(() => {
        if (error != null) {
            addAlert(`Error [${error.type}]: ${error.message}`, "error")
        }
    }, [error, addAlert])

    return (
        <FormikEntityForm
            entity={data}
            formContent={formContent}
            acceptButtonTitle={acceptButtonTitle}
            variant={variant}
            onSave={onSave}
            onChange={onChange}
        />
    )
}

const AsyncEntityForm = (props) => {
    return (
        <AsyncContextProvider fetcher={props.fetcher} loadComponent={Loading}>
            <AsyncEntityFormContent {...props} />
        </AsyncContextProvider>
    )
}

export default AsyncEntityForm;
