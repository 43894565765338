import constants from "../../../components/entity/constants";
import { createField, createLinkField, createArrayField } from "../../../components/helpers/fieldHelper";
import userPermissions from "../../../lib/user-permissions";


export const getTableColumns = (t, intermediaryUser, volcanoAuth) => ([
    createLinkField(
        createField(
            "name",
            t("intermediaries.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "intermediaries",
            field: "id",
        }
    ),
    createField(
        "parent_rates_collaborator.name",
        t("intermediaries.fields.parent_rates_collaborator"),
        constants.TEXT_TYPE,
    ),
    createField(
        "state.name",
        t("intermediaries.fields.state"),
        constants.TEXT_TYPE
    ),
    createField(
        "intermediary_type.name",
        t("intermediaries.fields.intermediary_type"),
        constants.TEXT_TYPE
    ),
    createField(
        "seller_type.name",
        t("intermediaries.fields.seller_type"),
        constants.TEXT_TYPE
    ),
    createField(
        "location_type.name",
        t("intermediaries.fields.location_type"),
        constants.TEXT_TYPE
    ),
    createField(
        "billing_type.name",
        t("intermediaries.fields.billing_type"),
        constants.TEXT_TYPE
    ),
    createField(
        "salesman.name",
        t("intermediaries.fields.crm_salesman"),
        constants.TEXT_TYPE,
        null,
        () => volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_SALESMAN_VIEW)
    ),
    createArrayField(
        createField(
            "payment_methods",
            t("intermediaries.fields.payment_methods"),
            constants.ARRAY_TYPE
        ),
        "name"
    ),
    createField(
        "booking_contact.contact_details.email",
        t("common.fields.contact_details.email"),
        constants.TEXT_TYPE
    ),
])