import { useEffect, useState, useMemo } from "react"
import { Box, TableCell, TableRow, Stack, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import Text from "../../../../components/Display/Text"
import _get from "lodash/get"
import _has from "lodash/has"
import Counter from "../../../../components/Counter/Counter"
import DataWrapper from "../../../../components/Display/DataWrapper"
import BookingRatesTable from "./BookingRatesTable"
import { getRateAgeRestriction, getRateQtyRestriction, getRatesNotInBooking, isMaxQtyLimit, isMaxQtyAttempted, buildResult } from "./BookingRates.functions"
import BookingRatesBasicData from "./BookingRatesBasicData"

const mapRate = (rate, unitPriceNet, newQty = null, newAmount = null) => {
    if (_has(rate, "rate_id")) { // booking rates
        return {
            id: rate.id,
            pvp: newAmount !== null && !unitPriceNet ? newAmount : rate.pvp,
            neto: newAmount !== null && unitPriceNet ? newAmount : rate.neto,
            qty: newQty !== null ? newQty : rate.qty,
            unit_price: newAmount !== null ? newAmount : (unitPriceNet ? rate.neto : rate.pvp),
            customer_types: rate.customer_types.map((ct) => ct.id),
        }
    } else { // product rates
        return {
            rate_id: parseInt(rate.id),
            pvp: parseFloat(rate.pvp),
            neto: parseFloat(rate.neto),
            qty: newQty !== null ? newQty : (_has(rate, "qty") ? rate.qty : 0),
            customer_types: rate.customer_types.map((ct) => ct.id),
        }
    }
}

const BookingRateRow = (props) => {
    const {
        rate,
        maxQtyAttempted,
        hideNetPrice,
        hideBasePrice,
        hideRefundCost,
        allowEditQty,
        allowEditAmount,
        currency,
        mainCurrency,
        exchangeRate,
        costExchangeRate,
        onChange
    } = props

    const [value, setValue] = useState(mapRate(rate, !hideNetPrice))
    const { t, i18n } = useTranslation("vbms")
    const ageRestriction = getRateAgeRestriction(rate)
    const qtyRestriction = getRateQtyRestriction(rate)

    const handleOnAmountChange = (newAmount) => {
        if (newAmount !== value.unit_price) {
            const mRate = mapRate(rate, !hideNetPrice, null, newAmount)

            setValue(mRate)
            onChange(mRate)
        }
    }

    const handleOnRateChange = (newQty) => {
        if (newQty !== value.qty) {
            const mRate = mapRate(rate, !hideNetPrice, newQty, value.unit_price)

            setValue(mRate)
            onChange(mRate)
        }
    }

    return (
        <TableRow key={rate.id}>
            <BookingRatesBasicData
                rate={rate}
                allowEditAmount={allowEditAmount}
                hideBasePrice={hideBasePrice}
                hideNetPrice={hideNetPrice}
                hideRefundCost={hideRefundCost}
                i18n={i18n}
                unitPrice={value.unit_price}
                t={t}
                currency={currency}
                mainCurrency={mainCurrency}
                exchangeRate={exchangeRate}
                costExchangeRate={costExchangeRate}
                onChange={handleOnAmountChange}
            />
            <TableCell align="center" sx={{ overflow: "hidden" }}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 1, marginBottom: -1 }}
                >
                    <Box>
                        <Counter
                            defaultValue={value.qty}
                            disabledMax={isMaxQtyLimit(rate, value.qty, maxQtyAttempted) || !allowEditQty}
                            min={allowEditQty ? qtyRestriction.min : value.qty}
                            max={qtyRestriction.max}
                            onChange={handleOnRateChange}
                            allowEdit={true}
                        />
                    </Box>
                    <Box sx={{ minHeight: 18 }}>
                        {_get(ageRestriction, "min", -1) >= 0 && (
                            <Text variant="body2">
                                {t("booking_widget.steps.rates.age_restriction", {
                                    from: ageRestriction.min,
                                    to: ageRestriction.max,
                                })}
                            </Text>
                        )}
                    </Box>
                </Stack>
            </TableCell>
        </TableRow>
    )
}

const BookingRatesSimple = (props) => {
    const {
        rates,
        selection,
        max,
        isRetailPrice,
        hideProductRates,
        hideRefundCost,
        allowEditQty,
        allowEditAmount,
        currency,
        mainCurrency,
        exchangeRate,
        costExchangeRate,
        onChange
    } = props

    const { t } = useTranslation("vbms")

    const finalRates = useMemo(() => {
        console.log(selection)
        return selection.length > 0 ? selection.map((rate) => mapRate(rate, !isRetailPrice)) : []
    }, [selection, isRetailPrice])

    const productRates = getRatesNotInBooking(rates, selection)

    const handleOnChange = (newRateValues) => {
        let index

        if (_has(newRateValues, "id")) { // booking rate
            index = finalRates.findIndex(
                (rate) => newRateValues.id === parseInt(rate.id)
            )

            finalRates[index] = {
                ...finalRates[index],
                qty: newRateValues.qty,
                pvp: newRateValues.pvp,
                neto: newRateValues.neto,
                unit_price: newRateValues.unit_price,
            }

            onChange(buildResult(selection, finalRates, false, allowEditAmount))
        } else { // product rate
            index = finalRates.findIndex(
                (rate) => newRateValues.rate_id === parseInt(rate.rate_id)
            )

            if (newRateValues.qty > 0) {
                if ((index >= 0) && (finalRates[index].qty !== newRateValues.qty)) {
                    finalRates[index].qty = newRateValues.qty
                    onChange(buildResult(selection, finalRates, false, allowEditAmount))
                } else if (index < 0) {
                    finalRates.push(newRateValues)
                    onChange(buildResult(selection, finalRates, false, allowEditAmount))
                }
            } else if (index >= 0) {
                finalRates.splice(index, 1)
                onChange(buildResult(selection, finalRates, false, allowEditAmount))
            }
        }
    }

    useEffect(() => {
        buildResult(selection, finalRates, false, allowEditAmount)
    }, [selection, finalRates, allowEditAmount])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={hideProductRates || productRates.length === 0 ? 12 : 6}>
                <DataWrapper
                    title={t("bookings.rates_edit_form.booking_rates.title")}
                    subtitle={t("bookings.rates_edit_form.booking_rates.subtitle")}
                    subtitleVariant="text"
                >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <BookingRatesTable
                            rates={selection}
                            maxQtyAttempted={isMaxQtyAttempted(max, finalRates)}
                            allowEditQty={allowEditQty}
                            allowEditAmount={allowEditAmount}
                            hideNetPrice={isRetailPrice}
                            hideBasePrice={true}
                            hideRefundCost={hideRefundCost}
                            RateRowComponent={BookingRateRow}
                            t={t}
                            currency={currency}
                            mainCurrency={mainCurrency}
                            exchangeRate={exchangeRate}
                            costExchangeRate={costExchangeRate}
                            onChange={handleOnChange}
                        />
                    </Grid>
                </DataWrapper>
            </Grid>
            {
                !hideProductRates && productRates.length > 0 && <Grid item xs={12} lg={6}>
                    <DataWrapper
                        title={t("bookings.rates_edit_form.product_rates.title")}
                        subtitle={t("bookings.rates_edit_form.product_rates.subtitle")}
                        subtitleVariant="text"
                    >
                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                            <BookingRatesTable
                                rates={productRates}
                                maxQtyAttempted={isMaxQtyAttempted(max, finalRates)}
                                allowEditQty={allowEditQty}
                                allowEditAmount={false}
                                hideNetPrice={isRetailPrice}
                                hideBasePrice={true}
                                hideExpandColumn={true}
                                hideRefundCost={true}
                                RateRowComponent={BookingRateRow}
                                t={t}
                                currency={currency}
                                mainCurrency={mainCurrency}
                                exchangeRate={exchangeRate}
                                costExchangeRate={costExchangeRate}
                                onChange={handleOnChange}
                            />
                        </Grid>
                    </DataWrapper>
                </Grid>}
        </Grid>
    )
}

export default BookingRatesSimple