import { Grid } from "@mui/material"
import { processFormAvailableFields } from "./utils"

const FormFieldGroup = ({ field, value }) => {

    const availableFields = processFormAvailableFields(value, field.options.fields)
    const mdSize = availableFields.length === 1 ? 12 : 6

    return (
        <Grid
            container
            spacing={2}
        >
            {availableFields.map((field, index) => {
                return <Grid item xs={12} md={mdSize} key={index}>{field.value}</Grid>
            })}
        </Grid >
    )
}

export default FormFieldGroup