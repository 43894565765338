import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import { createFormFieldset, createAsyncSelectFormField } from '../../../../../components/helpers/fieldHelper'
import { crmSchemaKeyItemsFetcher } from '../../../../../lib/form-options-fetchers';
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider"
import { useCache } from "../../../../../context/cache.context";

const ChangeState = ({ selection, onAction, onCancel }) => {

    const { t } = useTranslation("vbms")

    const fetcher = () => Promise.resolve(selection)
    const { apiClient } = useVolcanoApiClient()
    const cache = useCache()

    const formContent = [
        createFormFieldset("", [
            createAsyncSelectFormField(
                "state.id",
                t("intermediaries.fields.state"),
                () => crmSchemaKeyItemsFetcher("state", cache, apiClient, t, false, true),
                false
            ),
        ])
    ]

    const onConfirm = (result) => {
        onAction({
            id: selection.id,
            title: selection.label,
            data: {
                state: result.state.id
            }
        })
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default ChangeState