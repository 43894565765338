import constants from "../entity/constants"
import _get from "lodash/get"
import { getFieldValue } from "../entity/EntityField"
import FormFieldGroup from "./FormFieldGroup"
import { useFormikContext } from "formik"
import { useEffect } from "react"

/**
 * Form field wrapper. The field element should have this scheme:
 *
 *  {
 *    id:     field id in dot notation
 *    label:  field name
 *    type:   field type
 *    ...:    other props specific for a custom field type
 *  },
 *
 * @param {object} field
 * @param {object} entity
 */
const EntityFormField = ({ field, entity, isFilter }) => {
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        // check if the field has a handler for values update
        if (field.onValuesUpdate) {
            field.onValuesUpdate(values, setFieldValue)
        }
    }, [field, values, setFieldValue])

    let FormFieldComponent = null

    switch (field.facet) {
        case constants.FACET_TYPE_FIELD_GROUP:
            FormFieldComponent = FormFieldGroup
            break
        default:
            FormFieldComponent = _get(
                constants.FORM_FIELD_TYPE_COMPONENT_MAPPING,
                field.type
            )
    }

    const value = entity && getFieldValue(field, entity)

    return FormFieldComponent ? (
        <FormFieldComponent field={field} value={value} isFilter={isFilter} />
    ) : null
}

export default EntityFormField;
