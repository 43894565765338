import userPermissions from '../lib/user-permissions'
import BillingClientCreateInvoiceFormPage from "../pages/BillingClients/BillingClientCreateInvoiceFormPage"
import BillingClientFormPage from "../pages/BillingClients/BillingClientFormPage"
import BillingClientPage from "../pages/BillingClients/BillingClientPage/BillingClientPage"
import BillingClientsCollectionPage from "../pages/BillingClients/BillingClientsCollection/BillingClientsCollection"
import BookingAddPage from "../pages/Bookings/BookingAddPage"
import BookingCreateInvoiceFormPage from "../pages/Bookings/BookingCreateInvoiceFormPage"
import BookingPage from "../pages/Bookings/BookingPage/BookingPage"
import BookingRatesFormPage from "../pages/Bookings/BookingRatesFormPage/BookingRatesFormPage"
import BookingsCollectionGroupedPage from "../pages/Bookings/BookingsCollectionGrouped/BookingsCollectionGrouped"
import BookingsCollectionPage from "../pages/Bookings/BookingsCollection/BookingsCollection"
import IntermediariesCollectionPage from "../pages/Intermediaries/IntermediariesCollection/IntermediariesCollection"
import IntermediaryFormPage from "../pages/Intermediaries/IntermediaryFormPage"
import IntermediaryPage from "../pages/Intermediaries/IntermediaryPage/IntermediaryPage"
import InvoicePage from "../pages/Invoices/InvoicePage/InvoicePage"
import InvoicesCollectionPage from "../pages/Invoices/InvoicesCollection/InvoicesCollection"
import LiquidationPage from "../pages/Liquidations/LiquidationPage/LiquidationPage"
import LiquidationsCollectionPage from "../pages/Liquidations/LiquidationsCollection/LiquidationsCollection"
import LoginPage from "../pages/LoginPage"
import OfficeFormPage from "../pages/Intermediaries/Offices/OfficeFormPage"
import OfficePage from "../pages/Intermediaries/Offices/OfficePage/OfficePage"
import OfficesCollectionPage from "../pages/Intermediaries/Offices/OfficesCollection/OfficesCollection"
import OneClickBookingPage from "../pages/Bookings/OneClickBookingPage"
import PaymentTransactionResultPage from "../pages/PaymentTransactions/PaymentTransactionResultPage/PaymentTransactionResultPage"
import RefundPage from "../pages/Refunds/RefundPage/RefundPage"
import RefundsCollectionPage from "../pages/Refunds/RefundsCollection/RefundsCollection"
import SuggestionAddPage from "../pages/Suggestions/SuggestionAddPage/SuggestionAddPage"
import SuggestionPage from "../pages/Suggestions/SuggestionPage/SuggestionPage"
import SuggestionsCollectionPage from "../pages/Suggestions/SuggestionsCollection/SuggestionsCollection"
import TagFormPage from "../pages/Tags/TagFormPage"
import TagPage from "../pages/Tags/TagPage/TagPage"
import TagsCollectionPage from "../pages/Tags/TagsCollection/TagsCollection"
import UserFormPage from "../pages/Users/UserFormPage"
import UserPage from "../pages/Users/UserPage/UserPage"
import UsersCollectionPage from "../pages/Users/UsersCollection/UsersCollection"
import ValidationPage from "../pages/Validations/ValidationPage"
import VenueFormPage from "../pages/Venues/VenueFormPage"
import VenuePage from "../pages/Venues/VenuePage/VenuePage"
import VenuesCollectionPage from "../pages/Venues/VenuesCollection/VenuesCollection"
import DiscountCodesCollectionPage from "../pages/DiscountCodes/DiscountCodesCollection/DiscountCodesCollection"
import DiscountCodesPage from "../pages/DiscountCodes/DiscountCodesPage/DiscountCodesPage"
import DiscountCodeFormPage from "../pages/DiscountCodes/DiscountCodeFormPage"
import RolePage from '../pages/Roles/RolPage/RolePage'
import RolesCollectionPage from '../pages/Roles/RolesCollection/RolesCollection'
import RoleFormPage from '../pages/Roles/RoleFormPage'
import PaymentTransactionResultTokenPage from '../pages/PaymentTransactions/PaymentTransactionResultPage/PaymentTransactionResultTokenPage'
import EnterprisePage from "../pages/Enterprises/EnterprisePage/EnterprisePage"
import EnterpriseFormPage from '../pages/Enterprises/EnterpriseFormPage'


const availableRoutes = {
    login: {
        path: "/login",
        element: LoginPage,
        permissions: []
    },
    settings_users: {
        path: "/settings/users",
        element: UsersCollectionPage,
        permissions: [
            "view-any-user",
            userPermissions.PERM_APPLICATION_USERS_VIEW,
        ]
    },
    settings_users_add: {
        path: "/settings/users/add",
        element: UserFormPage,
        permissions: [
            "administer-users",
            "administer-application-users",
        ]
    },
    settings_users_edit: {
        path: "/settings/users/:entityId/edit",
        element: UserFormPage,
        permissions: [
            "administer-users",
            "administer-application-users",
            userPermissions.PERM_ADMIN_OWN_USER
        ]
    },
    settings_users_view: {
        path: "/settings/users/:entityId",
        element: UserPage,
        permissions: [
            "view-any-user",
            userPermissions.PERM_APPLICATION_USERS_VIEW,
            userPermissions.PERM_VIEW_OWN_USER
        ]
    },
    intermediaries: {
        path: "/settings/intermediaries",
        element: IntermediariesCollectionPage,
        permissions: [
            userPermissions.PERM_CRM_INTERMEDIARY_ADMIN,
            userPermissions.PERM_CRM_INTERMEDIARY_ADMIN_OWN,
        ]
    },
    intermediaries_intermediary_view: {
        path: "/settings/intermediaries/:entityId",
        element: IntermediaryPage,
        permissions: [
            userPermissions.PERM_CRM_INTERMEDIARY_VIEW,
            userPermissions.PERM_CRM_INTERMEDIARY_VIEW_OWN,
        ]
    },
    intermediaries_intermediary_edit: {
        path: "/settings/intermediaries/:entityId/edit",
        element: IntermediaryFormPage,
        permissions: [
            userPermissions.PERM_CRM_INTERMEDIARY_ADMIN,
            userPermissions.PERM_CRM_INTERMEDIARY_ADMIN_OWN,
        ]
    },
    intermediaries_intermediary_add: {
        path: "/settings/intermediaries/add",
        element: IntermediaryFormPage,
        permissions: [
            userPermissions.PERM_CRM_INTERMEDIARY_ADMIN,
            userPermissions.PERM_CRM_INTERMEDIARY_ADMIN_OWN,
            userPermissions.PERM_CRM_INTERMEDIARY_CREATE,
        ]
    },
    intermediary_offices: {
        path: "/settings/offices",
        element: OfficesCollectionPage,
        permissions: [
            userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW,
        ]
    },
    intermediary_offices_view: {
        path: "/settings/offices/:entityId",
        element: OfficePage,
        permissions: [
            userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW,
        ]
    },
    intermediary_offices_add: {
        path: "/settings/offices/add",
        element: OfficeFormPage,
        permissions: [
            "administer-any-intermediary-office",
        ]
    },
    intermediary_offices_edit: {
        path: "/settings/offices/:entityId/edit",
        element: OfficeFormPage,
        permissions: [
            "administer-any-intermediary-office",
        ]
    },
    billing_invoices: {
        path: "/invoices",
        element: InvoicesCollectionPage,
        permissions: [
            userPermissions.PERM_INVOICES_VIEW,
            userPermissions.PERM_INVOICES_VIEW_OWN
        ]
    },
    billing_invoices_view: {
        path: "/invoices/:entityId",
        element: InvoicePage,
        permissions: [
            "view-invoices",
            userPermissions.PERM_INVOICES_VIEW_OWN
        ]
    },
    billing_clients: {
        path: "/billing-clients",
        element: BillingClientsCollectionPage,
        permissions: [
            "administer-billing-information",
            "view-billing-information"
        ]
    },
    billing_clients_view: {
        path: "/billing-clients/:entityId",
        element: BillingClientPage,
        permissions: [
            "administer-billing-information",
            "view-billing-information"
        ]
    },
    billing_clients_add: {
        path: "/billing-clients/add",
        element: BillingClientFormPage,
        permissions: [
            "add-billing-information",
        ]
    },
    billing_clients_edit: {
        path: "/billing-clients/:entityId/edit",
        element: BillingClientFormPage,
        permissions: [
            "administer-billing-information",
        ]
    },
    billing_clients_create_client_invoice: {
        path: "/billing-clients/:entityId/create-invoice",
        element: BillingClientCreateInvoiceFormPage,
        permissions: [
            "create-invoice",
        ]
    },
    billing_clients_create_invoice: {
        path: "/billing-clients/create-invoice",
        element: BillingClientCreateInvoiceFormPage,
        permissions: [
            "create-invoice",
        ]
    },
    bookings: {
        path: "/orders/bookings",
        element: BookingsCollectionPage,
        permissions: [
            userPermissions.PERM_BOOKINGS_VIEW,
            userPermissions.PERM_BOOKINGS_VIEW_OWN
        ]
    },
    bookings_activity: {
        path: "/orders/bookings/activities",
        element: BookingsCollectionGroupedPage,
        permissions: [
            userPermissions.PERM_BOOKINGS_VIEW,
            userPermissions.PERM_BOOKINGS_VIEW_OWN
        ]
    },
    bookings_ocb: {
        path: "/orders/ocb",
        element: OneClickBookingPage,
        permissions: [
            userPermissions.PERM_BOOKINGS_ADMINISTER_ANY,
            userPermissions.PERM_BOOKINGS_ADMINISTER_OWN
        ]
    },
    bookings_add: {
        path: "/orders/bookings/add",
        element: BookingAddPage,
        permissions: [
            userPermissions.PERM_BOOKINGS_ADMINISTER_ANY,
            userPermissions.PERM_BOOKINGS_ADMINISTER_OWN
        ]
    },
    bookings_validate: {
        path: "/orders/bookings/validate",
        element: ValidationPage,
        permissions: [
            "validate-booking",
            userPermissions.PERM_BOOKINGS_VIEW,
        ]
    },
    bookings_view: {
        path: "/orders/bookings/:entityId",
        element: BookingPage,
        permissions: [
            userPermissions.PERM_BOOKINGS_VIEW,
            userPermissions.PERM_BOOKINGS_VIEW_OWN
        ]
    },
    bookings_rates_edit: {
        path: "/orders/bookings/:entityId/rates-edit",
        element: BookingRatesFormPage,
        permissions: [
            userPermissions.PERM_BOOKINGS_ADMINISTER_ANY,
            userPermissions.PERM_BOOKINGS_ADMINISTER_OWN
        ]
    },
    booking_create_client_invoice: {
        path: "/orders/bookings/:entityId/create-invoice",
        element: BookingCreateInvoiceFormPage,
        permissions: [
            "create-invoice",
        ]
    },
    payment_transactions_result: {
        path: "/orders/payment/result",
        element: PaymentTransactionResultPage,
        permissions: []
    },
    payment_transactions_result_token: {
        path: "/orders/payment/token",
        element: PaymentTransactionResultTokenPage,
        permissions: []
    },
    liquidations: {
        path: "/liquidations",
        element: LiquidationsCollectionPage,
        permissions: [
            "administer-any-liquidation",
            "view-any-liquidation"
        ]
    },
    liquidations_view: {
        path: "/liquidations/:entityId",
        element: LiquidationPage,
        permissions: [
            "view-any-liquidation"
        ]
    },
    refunds: {
        path: "/orders/refunds",
        element: RefundsCollectionPage,
        permissions: [
            "view-any-booking-refunds",
            "view-own-booking-refunds"
        ]
    },
    refunds_view: {
        path: "/orders/refunds/:entityId",
        element: RefundPage,
        permissions: [
            "view-any-booking-refunds",
            "view-own-booking-refunds"
        ]
    },
    suggestions: {
        path: "/suggestions",
        element: SuggestionsCollectionPage,
        permissions: [
            "administer-suggestion-moderation"
        ]
    },
    suggestions_view: {
        path: "/suggestions/:entityId",
        element: SuggestionPage,
        permissions: [
            "administer-suggestion-moderation"
        ]
    },
    suggestions_add: {
        path: "/suggestion-add",
        element: SuggestionAddPage,
        permissions: []
    },
    disfrutare_cablecar_precinct: {
        path: "/admin/precint_actions/index/2",
        element: null,
        permissions: ["disfrutare-access"]
    },
    disfrutare_observatory_precinct: {
        path: "/admin/precint_actions/index/9",
        element: null,
        permissions: ["disfrutare-access"]
    },
    disfrutare_activity_precincts: {
        path: "/admin/precints/activityPrecints",
        element: null,
        permissions: ["disfrutare-access"]
    },
    disfrutare_notifications: {
        path: "/admin/notifications/index",
        element: null,
        permissions: ["disfrutare-access"]
    },
    disfrutare_colaborator_product_rates: {
        path: "/admin/colaborators_product_rates/index/:entityId",
        element: null,
        permissions: ["disfrutare-access"]
    },
    tags: {
        path: "/settings/tags",
        element: TagsCollectionPage,
        permissions: [
            "view-tags"
        ]
    },
    tags_view: {
        path: "/settings/tags/:entityId",
        element: TagPage,
        permissions: [
            "view-tags"
        ]
    },
    tags_add: {
        path: "/settings/tags/add",
        element: TagFormPage,
        permissions: [
            "administer-tags-system",
            "administer-tags",
        ]
    },
    tags_edit: {
        path: "/settings/tags/:entityId/edit",
        element: TagFormPage,
        permissions: [
            "administer-tags-system",
            "administer-tags",
        ]
    },
    venues: {
        path: "/settings/venues",
        element: VenuesCollectionPage,
        permissions: [
            "view-venues"
        ]
    },
    venues_view: {
        path: "/settings/venues/:entityId",
        element: VenuePage,
        permissions: [
            "view-venues"
        ]
    },
    venues_add: {
        path: "/settings/venues/add",
        element: VenueFormPage,
        permissions: [
            "administer-venues"
        ]
    },
    venues_edit: {
        path: "/settings/venues/:entityId/edit",
        element: VenueFormPage,
        permissions: [
            "administer-venues"
        ]
    },
    discount_codes: {
        path: "/settings/discount_codes",
        element: DiscountCodesCollectionPage,
        permissions: [
            userPermissions.PERM_DISCOUNT_CODES_VIEW
        ]
    },
    discount_codes_view: {
        path: "/settings/discount_codes/:entityId",
        element: DiscountCodesPage,
        permissions: [
            userPermissions.PERM_DISCOUNT_CODES_VIEW
        ]
    },
    discount_codes_add: {
        path: "/settings/discount_codes/add",
        element: DiscountCodeFormPage,
        permissions: [
            userPermissions.PERM_DISCOUNT_CODES_ADMINISTER
        ]
    },
    discount_codes_edit: {
        path: "/settings/discount_codes/:entityId/edit",
        element: DiscountCodeFormPage,
        permissions: [
            userPermissions.PERM_DISCOUNT_CODES_ADMINISTER
        ]
    },
    settings_roles: {
        path: "/settings/roles",
        element: RolesCollectionPage,
        permissions: [
            userPermissions.PERM_ROL_ADMINISTER,
        ]
    },
    settings_roles_add: {
        path: "/settings/roles/add",
        element: RoleFormPage,
        permissions: [
            userPermissions.PERM_ROL_ADMINISTER,
        ]
    },
    settings_roles_edit: {
        path: "/settings/roles/:entityId/edit",
        element: RoleFormPage,
        permissions: [
            userPermissions.PERM_ROL_ADMINISTER,
        ]
    },
    settings_roles_view: {
        path: "/settings/roles/:entityId",
        element: RolePage,
        permissions: [
            userPermissions.PERM_ROL_ADMINISTER
        ]
    },
    enterprise: {
        path: "/settings/enterprise",
        element: EnterprisePage,
        permissions: [
            userPermissions.PERM_ACCESS_ANY_ENTERPRISE
        ]
    },
    enterprise_edit: {
        path: "/settings/enterprise/edit",
        element: EnterpriseFormPage,
        permissions: [
            userPermissions.PERM_ACCESS_ANY_ENTERPRISE,
        ]
    },
}

export default availableRoutes