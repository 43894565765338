import { useTranslation } from "react-i18next"
import constants from "../../components/entity/constants"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createAsyncSelectFormField, createCallbackField, createField, createFormField, createFormFieldGroup, createFormFieldset, createTextAreaFormField, createLanguagesMapping, createSelectFormField, createContactFormField, languagesCode2, createFormFieldOption, createDependentFormField } from "../../components/helpers/fieldHelper";
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import { useCache } from "../../context/cache.context";
import availableRoutes from "../../routes/availableRoutes";
import { crmSchemaKeyItemsFetcher } from "../../lib/form-options-fetchers";
import { enterprisesFetcher as extEnterprisesFetcher } from "../../lib/form-options-fetchers";
import _has from "lodash/has";
import _get from "lodash/get";
import _set from "lodash/set";
import _isEmpty from "lodash/isEmpty";
import _isArray from "lodash/isArray";
import { useVolcanoAuth } from "../../context/VolcanoAuthContext";
import userPermissions from "../../lib/user-permissions";
import useSnackBars from "../../components/Snackbar/snack-bar.context";
import { useEffect, useState, useCallback } from 'react';
import Loading from '../../components/Display/Loading';

const DEFAULT_COPY_ADDRESSES = {
    billing_contact: {
        copy_address: false,
        copy_address_from: "marketing_contact"
    },
    booking_contact: {
        copy_address: false,
        copy_address_from: "marketing_contact"
    },
    admin_contact: {
        copy_address: false,
        copy_address_from: "booking_contact"
    }
}

const IntermediaryFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()

    const [isReady, setIsReady] = useState(false)

    let copyAddresses = DEFAULT_COPY_ADDRESSES

    useEffect(() => {
        return () => {
            _set(copyAddresses, "billing_contact.copy_address", false)
            _set(copyAddresses, "booking_contact.copy_address", false)
            _set(copyAddresses, "admin_contact.copy_address", false)
        }
    }, [copyAddresses])

    const fetcher = useCallback(() => {
        setIsReady(false)

        if (!entityId) {
            setIsReady(true)
            return new Promise((res) => res({
                enterprise: {
                    id: volcanoAuth.user.corporate_account.enterprise.id
                },
                salesman: {
                    id: volcanoAuth.user.hasOwnProperty("crm_salesman") ? volcanoAuth.user.crm_salesman.id : null
                }
            }))
        }

        return apiClient.intermediary.getIntermediary(entityId).then((intermediary) => {
            intermediary.payment_methods = intermediary.payment_methods ? intermediary.payment_methods.map((paymentMethod) => paymentMethod.id.toString()) : []

            setIsReady(true)
            return intermediary
        })
    }, [apiClient, entityId, volcanoAuth.user])

    const checkFilledBillingContactRequiredFields = (billingContactFields, t) => {
        const billingContactRequiredFieds = [
            {
                id: "vat_number",
                label: "common.fields.vat_type.nif",
            },
            {
                id: "legal_person_type",
                label: "contact.fields.legal_person_type",
            },
            {
                id: "name",
                label: "common.fields.contact.name",
            },
            {
                id: "contact_details.country.id",
                label: "common.fields.contact_details.country",
            },
        ]

        let filledRequiredFields = []

        billingContactRequiredFieds.forEach((field) => {
            if (_get(billingContactFields, field.id, "") === "") {
                filledRequiredFields.push(t(field.label))
            }
        })
        return filledRequiredFields
    }

    const checkEmptyContactFields = (contactFields) => {
        const checkFields = [
            "legal_person_type",
            "vat_number",
            "iban",
            "contact_method",
            "name",
            "surname",
            "notify_closure",
            "restrict_calls",
            "title",
            "department",
            "contact_method",
            "contact_details.address_type.id",
            "contact_details.address",
            "contact_details.postal_code",
            "contact_details.locality",
            "contact_details.state",
            "contact_details.country.id",
            "contact_details.phone",
            "contact_details.phone_alternative",
            "contact_details.email"
        ]

        let emptyFields = true
        checkFields.forEach((field) => {
            if (_get(contactFields, field, "") !== "") {
                emptyFields = false
            }
        })
        return emptyFields
    }

    const onSaveHandler = (intermediary) => {
        setIsReady(false)

        // clone intermediary
        const intermediaryData = {
            ...intermediary,
        }

        // process payment_methods
        if (_has(intermediary, "payment_methods")) {
            _set(intermediaryData, "payment_methods", intermediary.payment_methods.map((paymentMethod) => ({ id: parseInt(paymentMethod) })))
        }

        if (_has(intermediaryData, "payment_methods") && intermediary.payment_methods?.includes("1")) { // Checking billing period if payment method is credit
            if (_get(intermediaryData, "billing_period.id", "") === "") {
                _set(intermediaryData, "billing_period.id", 3) // Mensual
            }
        } else {
            _set(intermediaryData, "billing_period.id", "")
        }

        // copy contact addresses
        if (copyAddresses.billing_contact.copy_address) {
            intermediaryData.billing_contact.contact_details =
                _get(intermediaryData, copyAddresses.billing_contact.copy_address_from + ".contact_details", intermediaryData.billing_contact.contact_details)
        }

        if (copyAddresses.booking_contact.copy_address) {
            intermediaryData.booking_contact.contact_details =
                _get(intermediaryData, copyAddresses.booking_contact.copy_address_from + ".contact_details", intermediaryData.booking_contact.contact_details)
        }

        if (copyAddresses.admin_contact.copy_address) {
            intermediaryData.admin_contact.contact_details =
                _get(intermediaryData, copyAddresses.admin_contact.copy_address_from + ".contact_details", intermediaryData.admin_contact.contact_details)
        }
        //

        if (_get(intermediaryData, "billing_contact.contact_details.country.id", "") !== "") {
            _set(intermediaryData, "billing_contact.country.id", _get(intermediaryData, "billing_contact.contact_details.country.id", ""))
        }

        // clear empty fields
        const checkFields = [
            "billing_type",
            "enterprise",
            "intermediary_type",
            "location_type",
            "parent_rates_collaborator",
            "seller_type",
            "state",
        ]

        checkFields.forEach((field) => {
            if (_has(intermediaryData, field) && _get(intermediaryData, field + ".id", "") === "") {
                _set(intermediaryData, field, null)
            }
        })

        let checkRequiredFields = true
        let requiredFieldsError = ""

        // check empty contact fields
        if (checkEmptyContactFields(intermediaryData.billing_contact)) {
            delete intermediaryData.billing_contact
        } else {
            let requiredFields = checkFilledBillingContactRequiredFields(intermediaryData.billing_contact, t)
            if (requiredFields.length > 0) {
                checkRequiredFields = false
                requiredFieldsError = requiredFields.join(", ")
                delete intermediaryData.billing_contact
            }
        }

        if (checkEmptyContactFields(intermediaryData.booking_contact)) {
            delete intermediaryData.booking_contact
        }
        if (checkEmptyContactFields(intermediaryData.admin_contact)) {
            delete intermediaryData.admin_contact
        }
        //

        const reg = /^(?:\d{11})$/
        if (!_isEmpty(intermediary.account_id) && !reg.exec(intermediary.account_id)) {
            addAlert(t("intermediaries.validation.account_id"), "error")
            setIsReady(true)
            return
        }

        if (_isEmpty(intermediary.marketing_contact.contact_details.email) && _isEmpty(intermediary.admin_contact.contact_details.email)) {
            addAlert((t("intermediaries.validation.required.email")), "error")
            setIsReady(true)
            return
        }

        if (!checkRequiredFields) {
            addAlert(t("intermediaries.validation.required.billing_contact") + requiredFieldsError, "error")
            setIsReady(true)
        } else {
            if (!entityId) {
                apiClient.intermediary.createIntermediary(intermediaryData, "application")
                    .then((intermediary) => {
                        navigate(availableRoutes.intermediaries_intermediary_view.path.replace(":entityId", intermediary.id))
                    })
                    .catch((error) => {
                        addAlert(error.message, "error")
                        setIsReady(true)
                    })
            } else {

                apiClient.intermediary.editIntermediary(entityId, intermediaryData, "application")
                    .then((intermediary) => {
                        navigate(availableRoutes.intermediaries_intermediary_view.path.replace(":entityId", entityId))
                    })
                    .catch((error) => {
                        addAlert(error.message, "error")
                        setIsReady(true)
                    })
            }
        }
    }

    const formContent = [
        createFormFieldset(t("intermediaries.form.fieldset_information"),
            [
                createAsyncSelectFormField(
                    "location_type.id",
                    t("intermediaries.fields.location_type"),
                    () => crmSchemaKeyItemsFetcher("location_type", cache, apiClient, t, false, true),
                    false,
                    null,
                    null,
                    true
                ),
                createFormField(
                    createField(
                        "name",
                        t("intermediaries.fields.comercial_name"),
                        constants.STRING_TYPE
                    ),
                    true
                ),
                createField(
                    "web",
                    t("intermediaries.fields.web"),
                    constants.STRING_TYPE
                ),
                createSelectFormField(
                    "language",
                    t("common.fields.language"),
                    Object.entries(createLanguagesMapping(t, languagesCode2(), true)).map(([key, value]) => ({ value: key, label: value })),
                    null,
                    true
                ),
                createField(
                    "business_group",
                    t("intermediaries.fields.business_group"),
                    constants.STRING_TYPE
                ),
                createField(
                    "delegation",
                    t("intermediaries.fields.delegation"),
                    constants.STRING_TYPE
                ),
            ]),

        createFormFieldset(t("intermediaries.form.fieldset_marketing_contact"), [
            createContactFormField(
                "marketing_contact",
                null,
                "natural_person",
                ["name", "surname", "notify_closure", "restrict_calls", "contact_method", "contact_details"],
                null,
                true
            ),
        ]),

        createFormFieldset(t("intermediaries.form.fieldset_billing_contact"),
            [
                createContactFormField(
                    "billing_contact",
                    null,
                    "legal_person",
                    ["legal_person_type", "vat_number", "name", "contact_details"],
                    copyAddresses.billing_contact.copy_address_from,
                    true,
                    null,
                    (values) => _set(copyAddresses, "billing_contact.copy_address", values.copy_address)
                ),
            ],
            (entity) => _isEmpty(entity.id) || volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_ADMIN),
        ),

        createFormFieldset(t("intermediaries.form.fieldset_booking_contact"), [
            createContactFormField(
                "booking_contact",
                null,
                "natural_person",
                ["name", "surname", "title", "contact_details"],
                copyAddresses.booking_contact.copy_address_from,
                false,
                null,
                (values) => _set(copyAddresses, "booking_contact.copy_address", values.copy_address)
            ),
        ]),

        createFormFieldset(t("intermediaries.form.fieldset_administration_contact"), [
            createContactFormField(
                "admin_contact",
                null,
                "natural_person",
                ["name", "surname", "title", "language", "contact_details"],
                copyAddresses.admin_contact.copy_address_from,
                false,
                null,
                (values) => _set(copyAddresses, "admin_contact.copy_address", values.copy_address)
            ),
        ]),

        createFormFieldset(t("intermediaries.form.fieldset_marketing_management"), [
            createFormFieldGroup([
                createAsyncSelectFormField(
                    "enterprise.id",
                    t("common.enterprise"),
                    () => extEnterprisesFetcher(cache, apiClient, t, false),
                    false,
                    (entity) => volcanoAuth.user.hasPermission(userPermissions.PERM_ACCESS_ANY_ENTERPRISE) && _isEmpty(entity.id),
                    null,
                    true
                ),
                createAsyncSelectFormField(
                    "salesman.id",
                    t("intermediaries.fields.crm_salesman"),
                    () => {
                        const options = [createFormFieldOption("", "-")]
                        return cache.get("crm_salesmen", []).then((items) => {
                            return options.concat(items.map((item) => ({ value: item.id, label: item.name })))
                        })
                    },
                    false,
                    () => volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_SALESMAN_VIEW)
                ),
            ]),
            createAsyncSelectFormField(
                "parent_rates_collaborator.id",
                t("intermediaries.fields.parent_rates_collaborator"),
                () => crmSchemaKeyItemsFetcher("parent_rates_collaborator_"+volcanoAuth.user.corporate_account.enterprise.id, cache, apiClient, t, false, true),
                false
            ),
            createFormFieldGroup([
                createAsyncSelectFormField(
                    "payment_methods",
                    t("intermediaries.fields.payment_methods"),
                    () => crmSchemaKeyItemsFetcher("payment_methods", cache, apiClient, t, false, true),
                    true,
                    (entity) => _isEmpty(entity.id) || volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_ADMIN),
                    (value) => _isArray(value) ? [value] : value,
                    true
                ),
            ]),
            createFormFieldGroup([
                createAsyncSelectFormField(
                    "intermediary_type.id",
                    t("intermediaries.fields.intermediary_type"),
                    () => crmSchemaKeyItemsFetcher("intermediary_type", cache, apiClient, t, false, true),
                    false,
                    null,
                    null,
                    true
                ),
                createAsyncSelectFormField(
                    "seller_type.id",
                    t("intermediaries.fields.seller_type"),
                    () => crmSchemaKeyItemsFetcher("seller_type", cache, apiClient, t, false, true),
                    false,
                    null,
                    null,
                    true
                ),
            ]),
        ]),

        createFormFieldset(
            t("intermediaries.form.fieldset_admin_management"),
            [
                createAsyncSelectFormField(
                    "state.id",
                    t("intermediaries.fields.state"),
                    () => crmSchemaKeyItemsFetcher("state", cache, apiClient, t, false, true),
                    false,
                    null,
                    null,
                    true
                ),
                createFormFieldGroup(
                    [
                        createAsyncSelectFormField(
                            "billing_type.id",
                            t("intermediaries.fields.billing_type"),
                            () => crmSchemaKeyItemsFetcher("billing_type", cache, apiClient, t, false, true),
                            false,
                            null,
                            null,
                            true
                        ),
                        createFormField(
                            createDependentFormField(
                                "billing_period.id",
                                t("intermediaries.fields.billing_period"),
                                constants.SELECT_TYPE,
                                (values) => {
                                    // Only shows if payment method credit
                                    if (values.payment_methods && values.payment_methods.includes("1")) {
                                        return crmSchemaKeyItemsFetcher("billing_period", cache, apiClient, t, false, false)
                                    }

                                    return []
                                }
                            ),
                            true
                        )
                    ]
                ),
                createFormFieldGroup([
                    createAsyncSelectFormField(
                        "payment_guarantee.id",
                        t("intermediaries.fields.payment_guarantee"),
                        () => crmSchemaKeyItemsFetcher("payment_guarantee", cache, apiClient, t, false, true),
                        false,
                        null,
                        null,
                        true
                    ),
                    createField(
                        "account_id",
                        t("intermediaries.fields.account_id"),
                        constants.STRING_TYPE
                    ),
                ])
            ],
            (entity) => volcanoAuth.user.hasPermission(userPermissions.PERM_ACCESS_ANY_ENTERPRISE)
        ),

        createFormFieldset(t("intermediaries.form.fieldset_comments"), [
            createTextAreaFormField(
                "comment",
                t("intermediaries.fields.comment"),
                3
            ),
        ]),
    ]

    return (
        <>
            <Loading open={!isReady} />
            <AsyncEntityFormPage
                fetcher={fetcher}
                title={createCallbackField(
                    "name",
                    t("intermediaries.form.edit_title"),
                    constants.STRING_TYPE,
                    (value, entity) => {
                        if (entity.id) {
                            return t("intermediaries.form.edit_title", {
                                name: value,
                            });
                        }
                        return t("intermediaries.form.add_title");
                    }
                )}
                onSave={onSaveHandler}
                formContent={formContent}
            />
        </>
    )
}

export default IntermediaryFormPage