import constants from '../components/entity/constants'
import {
  createCallbackField,
  createAsyncSelectFormField,
  createBooleanFormField,
  createCommonStateMapping,
  createDialogFormField,
  createField,
  createFormField,
  createFormFieldOption,
  createSelectFormField,
  discountCodesTypeMapping,
  typesClientsSelectMapping
} from '../components/helpers/fieldHelper'
import userPermissions from './user-permissions'
import _get from "lodash/get"
import _set from "lodash/set"

export const getExperiencesFilter = (apiClient, t) => {
    return createDialogFormField(
        "experience_id",
        t("experiences.filter.title"),
        false,
        {
            filterConfig: [
                createFormField(
                    createField(
                        "name",
                        t("experiences.fields.name"),
                        constants.STRING_TYPE
                    ),
                    false
                ),
                createSelectFormField(
                    "state",
                    t("common.states.title"),
                    [createFormFieldOption("", t("table.all"))].concat(
                        Object.entries(createCommonStateMapping(t))
                            .map(([key, value]) => createFormFieldOption(key, value))
                    )
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("experiences.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "state",
                    t("common.states.title"),
                    constants.STRING_TYPE
                ),
            ],
            fetcher: (params) => {
                return apiClient.catalog.experience.getExperiences({
                    ...params,
                    sort: "name",
                    sort_direction: "asc",
                })
            },
        },
        (value) => {
            return {
                id: value.id,
                label: value.name,
                entity: value,
            }
        }
    )
}

export const getActivitiesFilter = (apiClient, t, user) => {
    return createDialogFormField(
        "activity_id",
        t("bookings.filter.activity"),
        false,
        {
            filterConfig: [
                createAsyncSelectFormField(
                    "activity_config_id",
                    t("bookings.filter.activity_config"),
                    () => {
                        const options = [createFormFieldOption("", t("table.all"))]
                        return apiClient.activityManager.activityConfig.getActivityConfigs({ limit: 100 }, "compact").then((result) => {
                            return options.concat(
                                result.getItems()
                                    .map((item) => ({ value: item.id, label: item.name }))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                            )
                        })
                    },
                    false
                ),
                createFormField(
                    createField(
                        "date_from",
                        t("bookings.filter.activity_date"),
                        constants.DATE_TYPE
                    ),
                    false
                ),
            ],
            columns: [
                createField(
                    "activity_config.name",
                    t("bookings.filter.activity_config"),
                    constants.STRING_TYPE
                ),
                createField(
                    "date",
                    t("bookings.filter.activity_date"),
                    constants.DATE_TYPE
                ),
            ],
            fetcher: (params) => {
                return apiClient.activityManager.activity.getActivities({
                    ...params,
                    sort: "date",
                    sort_direction: "asc",
                })
            },
        },
        (value, language) => {
            const config = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }

            return {
                id: value.id,
                label: value.activity_config.name + " (" + Intl.DateTimeFormat(language, config).format(new Date(value.date)) + ")",
                entity: value,
            }
        },
        () => user.hasPermission('view-any-activity')
    )
}

export const getIntermediariesFilter = (apiClient, t, user, id, important, fetcher) => {
    return createDialogFormField(
        id || "collaborator_id",
        t("bookings.filter.intermediary"),
        true,
        {
            filterConfig: [
                createField(
                    "name",
                    t("intermediaries.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "vat_number",
                    t("common.fields.vat_type.nif"),
                    constants.STRING_TYPE
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("intermediaries.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "id_card",
                    t("common.fields.vat_type.nif"),
                    constants.STRING_TYPE
                ),
                createField(
                    "at.country.id",
                    t("common.fields.contact_details.country"),
                    constants.STRING_TYPE
                ),
                createField(
                    "enterprise.name",
                    t("bookings.fields.order.enterprise.title"),
                    constants.STRING_TYPE
                ),
            ],
            fetcher: fetcher || ((params) => {
                return apiClient.collaborator.getCollaborators({
                    ...params
                })
            }),
        },
        (value, language) => {
            return {
                id: value.id,
                label: value.name,
                entity: value,
            }
        },
        () => !user.isIntermediary(),
        important
    )
}

export const getIntermediariesClientsFilter = (apiClient, t, user, id, important, fetcher) => {
    return createDialogFormField(
        id || "collaborator_id",
        t("bookings.fields.order.customer.title") + ' / ' + t("bookings.filter.intermediary"),
        true,
        {
          filterConfig: [
            createField(
              "name",
              t("intermediaries.fields.name"),
              constants.STRING_TYPE
            ),
            createField(
              "vat_number",
              t("common.fields.vat_type.nif"),
              constants.STRING_TYPE
            ),
            createSelectFormField(
              "type",
              t("billing_client_create_invoice_widget.popup_select_client.type_client"),
              [
                {
                  value: null,
                  label: t("billing_client_create_invoice_widget.popup_select_client.types_client.all"),
                },
                {
                  value: 2,
                  label: t("billing_client_create_invoice_widget.popup_select_client.types_client.business"),
                },
                {
                  value: 1,
                  label: t("billing_client_create_invoice_widget.popup_select_client.types_client.person"),
                }
              ]
            ),
            createFormField(
                createSelectFormField(
                    "state",
                    t("products.fields.state"),
                    [createFormFieldOption("all", t("table.all"))].concat(
                        Object.entries(createCommonStateMapping(t))
                            .map(([key, value]) => createFormFieldOption(key, value))
                    )
                ),
                false,
                "active"
            )
          ],
            columns: [
              createField(
                "name",
                t("intermediaries.fields.name"),
                constants.STRING_TYPE
              ),
              createField(
                "vat_number",
                t("common.fields.vat_type.nif"),
                constants.STRING_TYPE
              ),
              createField(
                "type",
                t("common.fields.type_client"),
                constants.STRING_TYPE,
                typesClientsSelectMapping(t)
              ),
              createField(
                "country_id",
                t("common.fields.contact_details.country"),
                constants.STRING_TYPE
              ),
            ],
            fetcher: fetcher || ((params) => {
              return apiClient.billingClient.getSalesmen({
                ...params
              })
            }),
        },
        (value, language) => {

            return {
                id: value.id,
                label: value.name,
                entity: value,
            }
        },
        () => !user.isIntermediary(),
        important
    )
}

export const getIntermediaryOfficesFilter = (apiClient, t, user, id, important, fetcher) => {
    return createDialogFormField(
        id || "office_id",
        t("bookings.filter.office"),
        false,
        {
            filterConfig: [
                createField(
                    "name",
                    t("intermediaries.offices.fields.name"),
                    constants.TEXT_TYPE
                ),
                createBooleanFormField(
                    t,
                    "state",
                    t("intermediaries.offices.fields.active"),
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("intermediaries.offices.fields.name"),
                    constants.STRING_TYPE
                ),
            ],
            fetcher: fetcher || ((params) => {
                return apiClient.intermediary.getIntermediaryOffices(null, params)
            }),
        },
        (value, language) => {
            return {
                id: value.id,
                label: value.name,
                entity: value,
            }
        },
        () => !user.isIntermediary(),
        important
    )
}

export const getIntermediarySalesmanFilter = (apiClient, t, user, id, important, fetcher) => {
    return createDialogFormField(
        id || "salesman_id",
        t("bookings.filter.salesman"),
        false,
        {
            filterConfig: [
                createField(
                    "username",
                    t("settings_system_users.fields.username"),
                    constants.TEXT_TYPE
                ),
                createField(
                    "name",
                    t("settings_system_users.fields.name"),
                    constants.TEXT_TYPE
                ),
                createBooleanFormField(
                    t,
                    "active",
                    t("settings_system_users.fields.active"),
                ),
            ],
            columns: [
                createField(
                    "username",
                    t("settings_system_users.fields.username"),
                    constants.TEXT_TYPE
                ),
                createField(
                    "name",
                    t("settings_system_users.fields.name"),
                    constants.TEXT_TYPE
                ),
                createField(
                    "active",
                    t("settings_system_users.fields.active"),
                    constants.BOOLEAN_TYPE
                ),
            ],
            fetcher: fetcher || ((params) => {
                return apiClient.user.getApplicationUsers(params)
            }),
        },
        (value, language) => {
            return {
                id: value.id,
                label: value.name,
                entity: value,
            }
        },
        () => !user.isIntermediary(),
        important
    )
}

export const getDiscountCodesFilter = (apiClient, t, user) => {
    return createDialogFormField(
        "discount_code_id",
        t("bookings.filter.discount_code"),
        false,
        {
            filterConfig: [
                createFormField(
                    createField(
                        "name",
                        t("bookings.filter.name"),
                        constants.STRING_TYPE
                    ),
                    false
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("discount_codes.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "value",
                    t("discount_codes.fields.value"),
                    constants.NUMERIC_TYPE
                ),
                createField(
                    "type",
                    t("discount_codes.fields.type.name"),
                    constants.STRING_TYPE,
                    discountCodesTypeMapping(t)
                ),
                createField(
                    "usages",
                    t("discount_codes.fields.usages"),
                    constants.NUMERIC_TYPE
                ),
                createCallbackField(
                    "use_limit",
                    t("discount_codes.fields.use_limit"),
                    constants.STRING_TYPE,
                    (value) => value || t("discount_codes.constants.unlimited")
                ),
                createField(
                    "active",
                    t("common.states.active"),
                    constants.BOOLEAN_TYPE
                )
            ],
            fetcher: (params) => apiClient.catalog.discountCode.getDiscountCodes({ ...params })
        },
        (value) => ({
            id: value.id,
            label: value.name,
            entity: value
        }),
        () => user.hasPermission(userPermissions.PERM_CATALOG_VIEW)
    )
}

export const getProductsFilter = (apiClient, t, user, multiple, defaultParams = {}) => {
    return createDialogFormField(
        "product_id",
        t("products.filter.title"),
        multiple || false,
        {
            filterConfig: [
                createField(
                    "name",
                    t("products.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "sku",
                    t("products.fields.sku"),
                    constants.STRING_TYPE
                ),
                createField(
                    "experience_name",
                    t("products.fields.experience"),
                    constants.STRING_TYPE
                ),
                createFormField(
                    createSelectFormField(
                        "state",
                        t("products.fields.state"),
                        [createFormFieldOption("all", t("table.all"))].concat(
                            Object.entries(createCommonStateMapping(t))
                                .map(([key, value]) => createFormFieldOption(key, value))
                        )
                    ),
                    false,
                    defaultParams?.state || "all"
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("products.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "sku",
                    t("products.fields.sku"),
                    constants.STRING_TYPE
                ),
                createField(
                    "experience.name",
                    t("products.fields.experience"),
                    constants.STRING_TYPE
                ),
                createField(
                    "state",
                    t("products.fields.state"),
                    constants.STRING_TYPE,
                    createCommonStateMapping(t)
                ),
            ],
            fetcher: (params) => {
                    if (_get(defaultParams, "site_id", null)) {
                        _set(params, "site_id", _get(defaultParams, "site_id"))
                    }
                    return apiClient.catalog.product.getProducts( params )
            }
        },
        (value) => ({
            id: value.id,
            label: value.name,
            entity: value
        }),
        () => user.hasPermission(userPermissions.PERM_CATALOG_VIEW)
    )
}

export const getCurrenciesFilter = (apiClient, t, user, multiple, enterpriseId) => {
    return createDialogFormField(
        "currency_id",
        t("currencies.filter.title"),
        multiple || false,
        {
            columns: [
                createField(
                    "id",
                    "Id",
                    constants.STRING_TYPE
                ),
                createField(
                    "name",
                    t("currencies.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "code",
                    t("currencies.fields.code"),
                    constants.STRING_TYPE
                )
            ],
            fetcher: (params) => apiClient.enterprise.getCurrencies(enterpriseId, true)
        },
        (value) => ({
            id: value.id,
            label: value.id,
            entity: value
        }),
        () => user.hasPermission(userPermissions.PERM_ACCESS_ANY_ENTERPRISE)
    )
}
