import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { useNavigate } from 'react-router-dom'
import { getTableFilter } from './UsersCollection.filter'
import { getActions } from "./UsersCollection.actions"
import { getTableColumns } from './UsersCollection.columns'
import { getTableExport } from './UsersCollection.export'
import { useCache } from "../../../context/cache.context"
import _get from "lodash/get"
import _isArray from "lodash/isArray"

const UsersCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        highlight: (user) => user.active ? null : "background.error.main",
        columns: getTableColumns(t, volcanoAuth.user),
        fetcher: (params) => {
            
            let crmIntermediaryFilter = [];
            if (_get(params, "crm_intermediary_id", null)) {
                if (_isArray(_get(params, "crm_intermediary_id", null))) {
                    _get(params, "crm_intermediary_id", null).forEach(ci => {
                        crmIntermediaryFilter.push(ci.entity.crm_intermediary_id)
                    });
                } else {
                    crmIntermediaryFilter.push(_get(params, "crm_intermediary_id", null));
                }
            }

            const queryFilter = {
                ...params,
                collaborator_id: _get(params, "collaborator_id[0].id", null),
                crm_intermediary_id: crmIntermediaryFilter
            }

            return apiClient.user.getApplicationUsers(queryFilter)
        }
    };

    return (
        <CollectionPage
            title={t("settings_system_users.title")}
            subtitle={t("settings_system_users.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            table={table}
            filterConfig={filterConfig}
            onExport={getTableExport(apiClient, t)}
        />
    )
}

export default UsersCollectionPage;
