import { TableCell } from "@mui/material"

const DataTableCell = ({ column, scope, component, sortDirection, children }) => {
    const processAlign = (type) => {
        switch (type) {
            case "numeric":
            case "percentage":
            case "currency":
            case "datetime":
            case "date":
                return "right";
            case "download":
                return "center";
            default:
                return "left";
        }
    };
    return (
        <TableCell
            align={processAlign(column.type)}
            sortDirection={sortDirection}
            scope={scope || "column"}
            component={component || "td"}
        >
            {children}
        </TableCell>
    )
}

export default DataTableCell