import _isFunction from "lodash/isFunction"
import _has from "lodash/has"
import _get from "lodash/get"
import _set from "lodash/set"
import _isEmpty from "lodash/isEmpty"
import EntityFormField from "./EntityFormField"
import constants from "../entity/constants"
import _merge from "lodash/merge"

/**
 * Process the form fields and return the processed available fields. 
 * If the field has a condition, it will be evaluated.
 * 
 * @param {object} entity 
 * @param {array} fields 
 */
export const processFormAvailableFields = (entity, fields) => {
    return fields
        .filter((field) => _has(field, "condition") && _isFunction(field.condition) ?
            field.condition(entity) : true
        )
        .map((field, index) => {
            return {
                key: _has(field, 'id') ? field.id : null,
                name: _has(field, 'label') ? field.label : null,
                value: <EntityFormField key={index} field={field} entity={entity} />,
            }
        })
}

/**
 * Initialize the form values based on the entity and the form contents.
 *  
 * @param {object} entity 
 * @param {array} contents 
 * @returns 
 */
export const initFormValues = (entity, contents) => {
    let result = {};

    contents
        .filter((content) =>
            _has(content, "options") && _isFunction(content.options.condition)
                ? content.options.condition(entity)
                : true
        )
        .forEach((content) => {
            const fields = _has(content, "options") ? content.options.fields : contents
            fields
                .filter((field) =>
                    _has(field, "condition") && _isFunction(field.condition)
                        ? field.condition(entity)
                        : true
                )
                .forEach((field) => {
                    switch (field.facet) {
                        case constants.FACET_TYPE_FIELD_GROUP:
                            const fieldGroupValues = initFormValues(entity, field.options.fields)
                            result = _merge(result, fieldGroupValues)
                            break
                        default:
                            if ((field.type === constants.ASYNC_SELECT_TYPE || field.type === constants.ASYNC_TREEVIEW_TYPE) && (field.multiple)) {
                                if (_has(field, "valueMapper")) {
                                    const value = _get(entity, field.id, []) || []

                                    if (!_isEmpty(value)) {
                                        _set(result, field.id, value.map(field.valueMapper))
                                        break
                                    } else {
                                        _set(result, field.id, value)
                                        break
                                    }
                                }
                            }

                            let value = _get(entity, field.id, _get(field, "defaultValue", ""))
                            if (_isFunction(_get(field, "callback"))) {
                                value = field.callback(value, entity)
                            }
                            _set(result, field.id, value)
                            break
                    }
                })
        })

    return result
}

export const validate = (field, t, value) => {
    const { type, required } = field

    if (required && (value === undefined || value === null || value === "")) {
        return t("common.form.validation.required")
    }

    if (value && type === 'email' && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.\w+$/i.test(value))) {
        return t("common.form.validation.invalid_email")
    }

    if (type === 'phone') {
        if (value.length < 10) {
            return t("common.form.validation.invalid_phone")
        }
    }

    if (type === 'nif') {
        if (value.length < 8) {
            return t("common.form.validation.invalid_id_card")
        }
    }

    if (type === 'currency') {
        const regexp = /^-?\d+(\.\d{1,2})?$/

        if (!regexp.test(value)) {
            return t("common.form.validation.invalid_amount")
        }
    }

    if (type === 'numeric') {
        if (isNaN(value)) {
            return t("common.form.validation.invalid_number")
        }
    }

    return null
}
